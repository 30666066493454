import { useCallback, useMemo, useState } from 'react';
import { t } from 'services/utils/translation';
import useGetTabsPatch from './useGetTabsPatchs';
import { TAB_QUERY_KEY } from 'services/pathConstants';
import useGetTotalBookingInEntity from './useGetTotalBookingInEntity';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetCommonTabs from './useGetCommonTabs/useGetCommonTabs';
import { IMenuItemPagerV2 } from 'services/interfaces/IMenuItemPagerV2';
import useHaveAccessPolicyToActs from './useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';

const TARGET_ACCESS_POLICY = {
  serviceKey: 'vehicles',
  modelKey: 'Booking',
} as const;

const useGetExternalRequestTabs = (props: {
  id: number;
}): [IMenuItemPagerV2[], () => void, boolean] => {
  const { id } = props;
  const [refreshTabs, setRefreshTabs] = useState<boolean>(false);

  const {
    setRefreshDocuments,
    isLoadingDocument,
    commonTabs: [editTab, historyTab, documentTab],
  } = useGetCommonTabs({ parentUIElement: parentUIElement.order, id });

  const { warehousePatch, bookingPatch } = useGetTabsPatch(TAB_QUERY_KEY.order);

  const haveAccessPolicyReadBookingInArray = useHaveAccessPolicyToActs({
    targetAccessPolicy: TARGET_ACCESS_POLICY,
    targetAction: ['R'],
  });

  const haveAccessPolicyRead =
    haveAccessPolicyReadBookingInArray && haveAccessPolicyReadBookingInArray[0];

  const [totalBooking, isLoadingBooking, refreshBookingTotal] =
    useGetTotalBookingInEntity({
      pk: id,
      haveAccessPolicyReadBooking: haveAccessPolicyRead,
    });

  const refresh = useCallback(() => {
    setRefreshTabs(!refreshTabs);
    setRefreshDocuments();
    refreshBookingTotal();
  }, [refreshBookingTotal, refreshTabs, setRefreshDocuments]);

  const externalRequestTabs = useMemo(() => {
    const prevTabs = [
      editTab,
      historyTab,
      { name: t('Склад'), patch: warehousePatch },
      {
        name: t('Механизация'),
        patch: bookingPatch,
        recordsTotal: totalBooking,
      },

      documentTab,
    ];

    const BOOKING_TAB_INDEX = 2;

    !haveAccessPolicyRead && prevTabs.splice(BOOKING_TAB_INDEX, 1);

    return prevTabs;
  }, [
    editTab,
    historyTab,
    warehousePatch,
    bookingPatch,
    totalBooking,
    documentTab,
    haveAccessPolicyRead,
  ]);

  return [externalRequestTabs, refresh, isLoadingDocument || isLoadingBooking];
};

export default useGetExternalRequestTabs;
