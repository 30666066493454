import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { AttachmentsService } from 'models/AttachmentsService/AttachmentsService';
import useGetRequestsForAttachments from './useGetRequestsForAttachmentsProps';
import { parentUIElement } from 'services/constants/parentUIElement';
import { AttachmentClientDescriptionCreator } from 'models/AttachmentClientDescription/AttachmentClientDescriptionCreator';

const initialParamsBooking = { skip: 0, length: 0 };
type TAvailableParentUIElement = parentUIElement.ticket | parentUIElement.order;

const hasAttachments: Record<TAvailableParentUIElement, any> = {
  [parentUIElement.order]: {
    hasBookings: true,
    hasDocuments: true,
    hasInvoice: true,
  },
  [parentUIElement.ticket]: { hasDocuments: true },
} as const;

interface IUseShowAttachmentOnHoverOnHoverProps {
  isHovered: boolean;
  pk: number;
  parentUIName: TAvailableParentUIElement;
}

const useShowAttachmentOnHover = ({
  isHovered,
  pk,
  parentUIName,
}: IUseShowAttachmentOnHoverOnHoverProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [descriptionAttachment, setDescriptionAttachment] =
    useState<string>('');

  const getInitialParamsDocument = useCallback(
    (pk: number) => ({
      parent_ui_element: { [parentUIName]: pk },
      skip: 0,
      length: 10,
    }),
    [parentUIName]
  );

  const { getBooking, getDocuments, getExternalRequest } =
    useGetRequestsForAttachments(hasAttachments[parentUIName]);

  const requestsAndGettingDescriptions = useCallback(
    async () => {
      setIsLoading(true);
      const paramsBooking = {
        ...initialParamsBooking,
        booking_external_request_fk: pk,
      };
      const paramsDocuments = getInitialParamsDocument(pk);
      const parasExternalRequestRead = { pk };
      const [payloadBooking, payloadDocuments, payloadExternalRequest] =
        await Promise.all([
          getBooking && getBooking(paramsBooking),
          getDocuments && getDocuments(paramsDocuments),
          getExternalRequest && getExternalRequest(parasExternalRequestRead),
        ]);

      const attachments = new AttachmentsService({
        bookings: payloadBooking?.list_of_entities,
        documents: payloadDocuments?.result?.list_of_entities,
        invoices:
          payloadExternalRequest?.external_request
            ?.external_request_list_of_invoices,
      });

      const attachmentClientDescription =
        new AttachmentClientDescriptionCreator(attachments).create(
          parentUIName
        );

      setIsLoading(false);
      setDescriptionAttachment(
        attachmentClientDescription.attachmentDescription
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isHovered) {
      void requestsAndGettingDescriptions();
    }
  }, [isHovered, requestsAndGettingDescriptions]);

  return {
    descriptionAttachment,
    isLoading,
  };
};

export default useShowAttachmentOnHover;
