import { AttachmentsService } from 'models/AttachmentsService/AttachmentsService';
import { AttachmentClientDescriptionTask } from './AttachmentClientDescriptionTask';
import { parentUIElement } from 'services/constants/parentUIElement';
import { AttachmentClientDescriptionBase } from './AttachmentClientDescriptionBase';

export class AttachmentClientDescriptionCreator {
  protected attachments: AttachmentsService;
  constructor(attachments: AttachmentsService) {
    this.attachments = attachments;
  }

  create = (parentUIName: parentUIElement): AttachmentClientDescriptionBase => {
    switch (parentUIName) {
      case parentUIElement.ticket: {
        return new AttachmentClientDescriptionTask(this.attachments);
      }
      case parentUIElement.order: {
        return new AttachmentClientDescriptionBase(this.attachments);
      }
      default: {
        throw new Error('create AttachmentClientDescription failed');
      }
    }
  };
}
