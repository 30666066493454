import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC, memo, useEffect, PropsWithChildren, useMemo } from 'react';
import DocumentsPage from 'pages/documents/DocumentsPage/DocumentsPage';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import {
  getHistoryTask,
  readTaskApiV2,
} from '../../../../services/api/tasks/tasks/tasksApiV2';
import { IGetHistoryTaskPayload } from '../../../../services/api/tasks/tasks/IGetHistoryTaskPayload';
import TaskHistory from 'components/tasks/TaskHistory';
import TaskForm from 'components/tasks/TaskForm/TaskForm';
import toFormValuesTask from 'store/utils/toFormValuesTaskV2';
import { IGetTaskPayload } from '../../../../services/api/tasks/tasks/IGetTaskPayload';
import usePreventReload from 'hooks/usePreventReload';
import TaskHeader from 'components/tasks/TaskHeader/TaskHeader';
import useGetRefreshingData from '../../../../hooks/useGetRefreshingData';
import {
  TAB_QUERY_PARAMS,
  TAB_QUERY_KEY,
} from '../../../../services/pathConstants';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import PagerWithParameterSwitch from 'components/misc/Pagers/PagerWithParameterSwitch/PagerWithParameterSwitch';
import { getHasUnsavedValuesFromStore } from 'store/form/selectors';
import { blockNavigationForm } from 'store/form/actions';
import { setValueDifference } from '../../../../store/form/actions';
import useHaveAccessPolicyToActs from '../../../../hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import {
  clearTaskFieldValue,
  saveTaskInStore,
  setInitialTask,
} from '../../../../store/task/actions';
import { targetAccessPolicyTask } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyTask';
import { parentUIElement } from 'services/constants/parentUIElement';
import useGetCommonTabs from 'hooks/useGetCommonTabs/useGetCommonTabs';
import { useParams } from 'react-router-dom';
import useTaskSuccessHandler from '../hooks/useTaskSuccessHandler';

const convertHistoryData = (data: IGetHistoryTaskPayload) => ({
  rows: data?.internal_task_list_of_edits,
  creationInfo: {
    internal_task_datetime_added: data?.internal_task_datetime_added,
    internal_task_author_dk: data?.internal_task_author_dk,
    internal_task_author: data?.internal_task_author,
  },
});

const extractConvertTask =
  (isDuplicate?: boolean, isChild?: boolean) => (data: IGetTaskPayload) =>
    toFormValuesTask(data?.internal_task, isDuplicate, isChild);

type TaskEditPagePropsType = {
  isDuplicate?: boolean;
  isChild?: boolean;
};

type PropsType = PropsWithChildren<TaskEditPagePropsType>;

const TaskEditPage: FC<PropsType> = (props) => {
  const { isDuplicate, isChild } = props;

  const { id } = useParams();

  const pk = Number(id);

  const convertTask = extractConvertTask(isDuplicate, isChild);

  const {
    setRefreshDocuments: refreshTabs,
    isLoadingDocument: isLoadingCountTabs,
    commonTabs: tabs,
  } = useGetCommonTabs({
    parentUIElement: parentUIElement.ticket,
    id: pk,
  });

  const successHandler = useTaskSuccessHandler();

  const dispatch = useDispatch();

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyTask,
    targetAction: ['U'],
  });

  //TODO: CREATE HOOK useGetTaskFormState
  const isPruned: boolean = useSelector(
    (state: RootStateOrAny) => state.tasks.fieldValues?.internal_task_is_pruned
  );

  const viewOnly =
    !haveAccessToUpdate || (isPruned && !isChild && !isDuplicate);

  const isShowPrompt: boolean = useSelector(getHasUnsavedValuesFromStore);

  const initialParams = useMemo(
    () => ({
      pk,
    }),
    [pk]
  );

  const {
    data: historyData,
    refreshData: refreshHistory,
    isLoading: isLoadingHistory,
  } = useGetRefreshingData({
    getDataApi: getHistoryTask,
    initialData: null,
    params: initialParams,
    condition: !!pk,
    convertData: convertHistoryData,
  });

  const {
    data: task,
    isLoading: isLoadingTask,
    refreshData: refreshTask,
  } = useGetRefreshingData({
    getDataApi: readTaskApiV2,
    initialData: null,
    params: initialParams,
    condition: !!pk,
    convertData: convertTask,
  });

  usePreventReload(isShowPrompt);

  useEffect(() => {
    if (pk && task) {
      dispatch(saveTaskInStore(task));
      dispatch(setInitialTask(task));
    }
    return () => {
      dispatch(setInitialTask({}));
      dispatch(clearTaskFieldValue());
      dispatch(blockNavigationForm(false));
      dispatch(setValueDifference([]));
    };
  }, [dispatch, task, pk]);

  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.edit]: (
      <TaskForm
        successHandler={successHandler}
        taskPk={pk}
        viewOnly={viewOnly}
        refreshHistory={refreshHistory}
        isLoadingTask={isLoadingTask}
        isDuplicate={isDuplicate}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <TaskHistory
        taskListOfHistoryEntity={historyData?.rows}
        creationTaskInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.ticket}
        parentUIPk={pk}
        refreshTabs={refreshTabs}
        viewOnly={viewOnly}
      />
    ),
  };

  const targetTab =
    (useGetQueryParameter(TAB_QUERY_KEY.ticket) as keyof typeof CONTENT_MAP) ||
    TAB_QUERY_PARAMS.edit;

  const ContentForRender = CONTENT_MAP[targetTab];
  return (
    <MainLayout>
      <Subheader />
      <TaskHeader
        taskPk={pk}
        isDuplicate={isDuplicate}
        task={task}
        refreshForm={refreshTask}
      />
      {!isDuplicate && (
        <PagerWithParameterSwitch
          items={tabs}
          targetTab={targetTab}
          isLoadingCountTabs={isLoadingCountTabs}
        />
      )}
      {ContentForRender}
    </MainLayout>
  );
};

export default memo(TaskEditPage);
